import actions from './actions'

const initState = {
  responsePostAnalytics: undefined,
  responsePutAnalytics: undefined,
}

export default function analyticsReducer(state = initState, action) {
  switch (action.type) {
    case 'RESPONSE_POST_ANALYTICS':
      return {
        ...state,
        responsePostAnalytics: action.payload,
      }
    case 'RESPONSE_PUT_ANALYTICS':
      return {
        ...state,
        responsePutAnalytics: action.payload,
      }
    default:
      return state
  }
}
