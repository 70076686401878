const shipActions = {
  CREATE_ORIGIN: 'CREATE_ORIGIN',
  CREATE_SHIP: 'CREATE_SHIP',
  EDIT_SHIP: 'EDIT_SHIP',
  DELETE_SHIP: 'DELETE_SHIP',
  ADD_PACKAGE: 'ADD_PACKAGE',
  CREATE_SHIPMENT: 'CREATE_SHIPMENT',
  SELECTED_DESTINY: 'SELECTED_DESTINY',
  EDIT_MAILFORM: 'EDIT_MAILFORM',
  EDIT_PACKAGE: 'EDIT_PACKAGE',
  DELETE_PACKAGE: 'DELETE_PACKAGE',
  ADD_NEW_PACKAGE: 'ADD_NEW_PACKAGE',
  CLEAR_SELECTED_ID: 'CLEAR_SELECTED_ID',
  CLEAR_STATE: 'CLEAR_STATE',

  createOrigin: (origin) => {
    return (dispatch) => {
      dispatch({
        type: shipActions.CREATE_ORIGIN,
        origin,
      })
    }
  },
  createShip: (destiny) => {
    let ship = { id: new Date().getTime(), destiny }
    return (dispatch) => {
      dispatch({
        type: shipActions.CREATE_SHIP,
        ship,
      })
    }
  },
  editShip: (id) => {
    return (dispatch) => {
      dispatch({
        type: shipActions.EDIT_SHIP,
        id,
      })
    }
  },
  deleteShip: (id) => {
    return (dispatch, getState) => {
      const filteredShipments = getState().Shipment.shipments.filter((item) => item.id != id)
      dispatch({
        type: shipActions.DELETE_SHIP,
        filteredShipments,
      })
    }
  },
  changeDestiny: (id) => {
    return (dispatch, getState) => {
      const index = getState().Shipment.shipments.findIndex((el) => el.id === id)
      dispatch({
        type: shipActions.SELECTED_DESTINY,
        id,
      })
    }
  },
  addPackage: (dataPackage) => {
    let newPackage = { id: new Date().getTime(), ...dataPackage }
    return (dispatch) => {
      dispatch({
        type: shipActions.ADD_PACKAGE,
        newPackage,
      })
    }
  },
  addNewPackage: () => {
    return (dispatch, getState) => {
      let onShipments = getState().Shipment.shipments
      const index = onShipments.findIndex((el) => el.id == getState().Shipment.selectedId)
      let newPackages = onShipments[index].packages.concat(getState().Shipment.packages)
      onShipments[index].packages = newPackages
      dispatch({
        type: shipActions.ADD_NEW_PACKAGE,
        onShipments,
      })
    }
  },
  createShipment: () => {
    return (dispatch, getState) => {
      let newShipment = {
        ...getState().Shipment.ship,
        packages: getState().Shipment.packages,
      }
      dispatch({
        type: shipActions.CREATE_SHIPMENT,
        newShipment,
      })
    }
  },
  editMail: (values) => {
    let newDestiny = values
    return (dispatch, getState) => {
      let onShipments = getState().Shipment.shipments
      const index = onShipments.findIndex((el) => el.id == getState().Shipment.selectedId)
      onShipments[index].destiny = newDestiny
      dispatch({
        type: shipActions.EDIT_MAILFORM,
        onShipments,
      })
    }
  },
  editPackage: (values, idPackage) => {
    let newPackage = { id: idPackage, ...values }
    return (dispatch, getState) => {
      let onShipments = getState().Shipment.shipments
      const indexShip = onShipments.findIndex((el) => el.id == getState().Shipment.selectedId)
      const indexPackage = onShipments[indexShip].packages.findIndex((el) => el.id == idPackage)
      onShipments[indexShip].packages[indexPackage] = newPackage
      dispatch({
        type: shipActions.EDIT_PACKAGE,
        onShipments,
      })
    }
  },
  deletePackage: (idPackage) => {
    return (dispatch, getState) => {
      let onShipments = getState().Shipment.shipments
      const indexShip = onShipments.findIndex((el) => el.id == getState().Shipment.selectedId)
      const filteredPackages = onShipments[indexShip].packages.filter(
        (pack) => pack.id != idPackage,
      )
      onShipments[indexShip].packages = filteredPackages
      dispatch({
        type: shipActions.DELETE_PACKAGE,
        onShipments,
      })
    }
  },
  clearSelectedId: () => {
    return (dispatch) => {
      dispatch({
        type: shipActions.CLEAR_SELECTED_ID,
      })
    }
  },
  clearState: () => {
    return (dispatch) => {
      dispatch({
        type: shipActions.CLEAR_STATE,
      })
    }
  },
}

export default shipActions
