import shipActions from './actions'

const initState = {
  shipments: [],
  ship: {},
  packages: [],
  origin: null,
  selectedId: null,
}

export default function shipmentsReducer(state = initState, action) {
  const { ship, origin, shipments, packages } = state
  switch (action.type) {
    case shipActions.CREATE_ORIGIN:
      return {
        ...state,
        origin: action.origin,
      }
    case shipActions.CREATE_SHIP:
      return {
        ...state,
        ship: action.ship,
      }
    case shipActions.EDIT_SHIP:
      return {
        ...state,
        selectedId: action.id,
      }
    case shipActions.DELETE_SHIP:
      return {
        ...state,
        shipments: action.filteredShipments,
      }
    case shipActions.ADD_PACKAGE:
      return {
        ...state,
        packages: packages.concat(action.newPackage),
      }
    case shipActions.CREATE_SHIPMENT:
      return {
        ...state,
        shipments: shipments.concat(action.newShipment),
        ship: {},
        packages: [],
      }
    case shipActions.SELECTED_DESTINY:
      return {
        ...state,
        selectedId: action.id,
      }
    case shipActions.ADD_NEW_PACKAGE:
      return {
        ...state,
        shipments: action.onShipments,
        packages: [],
      }
    case shipActions.EDIT_MAILFORM:
      return {
        ...state,
        shipments: action.onShipments,
      }
    case shipActions.EDIT_PACKAGE:
      return {
        ...state,
        shipments: action.onShipments,
      }
    case shipActions.DELETE_PACKAGE:
      return {
        ...state,
        shipments: action.onShipments,
      }
    case shipActions.CLEAR_SELECTED_ID:
      return {
        ...state,
        selectedId: null,
      }
    case shipActions.CLEAR_STATE:
      return {
        ...state,
        shipments: [],
        ship: {},
        packages: [],
        origin: null,
        selectedId: null,
      }
    default:
      return state
  }
}
