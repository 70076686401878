import axios from 'axios'
import parseApiUrl from '../library/helpers/parseApiUrl'
import { jwtHeader } from './jwtHeader'
// const API_URL = process.env.REACT_APP_API_DEV
const API_URL = parseApiUrl(process.env.REACT_APP_API_URL)

export const getHowToUse = async () => {
  try {
    const res = await axios.get(`${API_URL}/bulkupload/howtouse`, {
      headers: jwtHeader(),
    })
    return res
  } catch (error) {
    console.log('ERROR SERVICE SETTING', error.message)
    return { status: 400 }
  }
}

export const postCSV = async (data) => {
  try {
    const string = {
      fileBase64: data,
    }
    const res = await axios.post(`${API_URL}/bulkupload/import`, string, {
      headers: jwtHeader(),
    })
    console.log(res, 'response importcsv')
    return res
  } catch (error) {
    console.log('ERROR SERVICE SETTING', error.message)
    return error.message
  }
}

// endpoint de envios de ordenes, pasar id en endpoint
export const getShipmentsBulkUpload = async (buldLoadId) => {
  try {
    const res = await axios.get(`${API_URL}/bulkupload/ordersinfo/${buldLoadId}`, {
      headers: jwtHeader(),
    })
    console.log(res, 'response buloadID')
    return res
  } catch (error) {
    console.log('ERROR SERVICE SETTING', error.message)
    return { status: 400 }
  }
}
// endpoint de ShipmentbyID
export const getShipmentByID = async (dataShipment) => {
  const orderBulkUploadId = dataShipment.orderBulkUploadId
  const shipmentId = dataShipment.shipmentId

  try {
    const res = await axios.get(
      `${API_URL}/bulkupload/ordersinfo/${orderBulkUploadId}/${shipmentId}`,
      {
        headers: jwtHeader(),
      },
    )
    console.log(res, 'respuesta shipment id')
    return res
  } catch (error) {
    console.log('ERROR SERVICE SETTING', error.message)
    return { status: 400 }
  }
}
// trae envio por id para la pagina de pago
export const getShipmentByIDPayment = async ({ dataShipment, responseCsv }) => {
  const shipmentId = dataShipment.shipment_bulk_upload_id

  try {
    const res = await axios.get(`${API_URL}/bulkupload/ordersinfo/${responseCsv}/${shipmentId}`, {
      headers: jwtHeader(),
    })
    console.log(res, 'respuesta shipment id')
    return res
  } catch (error) {
    console.log('ERROR SERVICE SETTING', error.message)
    return { status: 400 }
  }
}

// endpoint que me va a traer pago por ID
export const getPaymentID = async (dataBulkLoad) => {
  try {
    const res = await axios.get(`${API_URL}/bulkupload/payinfo/${dataBulkLoad.data}`, {
      headers: jwtHeader(),
    })
    console.log(res, 'response getPayment')
    return res
  } catch (error) {
    console.log('ERROR SERVICE SETTING', error.message)
    return { status: 400 }
  }
}

// endpoint de Descuento order
export const getApplyDiscount = async ({ responseImportCsv, codeDiscount }) => {
  try {
    const res = await axios.get(`${API_URL}/bulkupload/${responseImportCsv.data}/${codeDiscount}`, {
      headers: jwtHeader(),
    })
    return res
  } catch (error) {
    console.log('ERROR SERVICE SETTING', error.message)
    return { status: 400 }
  }
}

// lista de pagos

export const getShipmentList = async (dataBulkLoad) => {
  try {
    const res = await axios.get(`${API_URL}/bulkupload/orders/list/${dataBulkLoad.data}`, {
      headers: jwtHeader(),
    })
    console.log(res, 'response shipment List')
    return res
  } catch (error) {
    console.log('ERROR SERVICE SETTING', error.message)
    return { status: 400 }
  }
}
