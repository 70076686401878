import axios from 'axios'
import parseApiUrl from '../library/helpers/parseApiUrl'
import { jwtHeader } from './jwtHeader'
// const API_URL = process.env.REACT_APP_API_DEV
const API_URL = parseApiUrl(process.env.REACT_APP_API_URL)

export const postAnalyticsCampaign = async (body) => {
  try {
    const res = await axios.post(`${API_URL}/analytics/`, body, {
      headers: jwtHeader(),
    })
    return res
  } catch (error) {
    console.log('ERROR SERVICE SETTING', error.message)
    return error.message
  }
}

export const putAnalytics = async (user_id) => {
  try {
    const res = await axios.put(`${API_URL}/analytics/${user_id}`, {
      headers: jwtHeader(),
    })
    return res
  } catch (error) {
    throw error
  }
}
