import {
  CHECK_AUTHORIZATION,
  LOADING_END,
  LOADING_START,
  LOGOUT,
  SAVE_USER,
  UPSERT_USER,
} from './types'

import axios from 'axios'
import { jwtHeader } from '../services/jwtHeader'
import parseApiUrl from '../library/helpers/parseApiUrl'

const API_URL = parseApiUrl(process.env.REACT_APP_API_URL)

export const getUserAction = () => {
  return async (dispatch) => {
    const res = await axios.get(`${API_URL}/auth/user-profile`, {
      headers: jwtHeader(),
    })

    if (res.status < 300) {
      const user = await res.data.data
      const jwt = jwtStorage
      dispatch({ type: SAVE_USER, payload: { user } })
    }

    if (res.status === 401 || res.status === 403) {
      throw new Error('Email or password is invalid. Please try again.')
    } else {
      throw new Error('Network error. Please try again.')
    }
  }
}

export const login = (email, password, rememberMe, tienda_nube, store_id) => {
  return async (dispatch) => {
    dispatch({ type: LOADING_START })
    var formdata = new FormData()
    formdata.append('email', email)
    formdata.append('password', password)
    formdata.append('tienda_nube', tienda_nube)
    formdata.append('store_id', store_id)

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    }

    const res = await fetch(`${API_URL}/auth/login`, requestOptions)

    if (res.status < 300) {
      const body = await res.json()
      const { access_token, user } = body.data
      const jwt = access_token
      dispatch({ type: UPSERT_USER, payload: { jwt, user, rememberMe } })
    }

    dispatch({ type: LOADING_END })

    if (res.status === 401 || res.status === 403) {
      const body = await res.json()
      if (body.error === 'Tu usuario está deshabilitado') {
        throw new Error('Tu usuario está deshabilitado. Por favor comunicate con 0810-333-1700.')
      } else if (body.error === 'Confirma tu email antes de ingresar') {
        throw new Error(body.error)
      } else {
        throw new Error('El email o la contraseña son inválidos. Por favor intente de nuevo.')
      }
    } else {
      throw new Error('Error en el servidor. Por favor intente de nuevo.')
    }
  }
}

export const logout = () => ({
  type: LOGOUT,
})

export const checkAuthorization = () => ({ type: CHECK_AUTHORIZATION })
