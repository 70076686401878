import React, { Suspense, lazy, useEffect, useState } from 'react'
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import ErrorBoundary from './ErrorBoundary'
import Loader from '@iso/components/utility/loader'
import { PUBLIC_ROUTE } from './route.constants'
import { getUserAction } from './actions'
import { useSelector } from 'react-redux'
import { useThunkDispatch } from '@iso/redux/hooks/useThunkDispatch'

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'))

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.LANDING_TIENDA_NUBE,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import('@iso/containers/Pages/404/404')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import('@iso/containers/Pages/500/500')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/SignUp/SignUp')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP_TIENDA_NUBE,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/SignUp/SignUp')),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() => import('@iso/containers/Pages/ForgotPassword/ForgotPassword')),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() => import('@iso/containers/Pages/ResetPassword/ResetPassword')),
  },
  {
    path: PUBLIC_ROUTE.TRACKER_ID,
    component: lazy(() => import('./containers/Pages/Tracker/Tracker')),
  },
  {
    path: PUBLIC_ROUTE.ANON_QUOTE,
    component: lazy(() => import('./containers/Pages/AnonQuote/AnonQuote')),
  },
  {
    path: PUBLIC_ROUTE.PAY,
    exact: true,
    component: lazy(() => import('./containers/Pages/Pay/Pay')),
  },
]
function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useSelector((state) => state.User.jwt)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: PUBLIC_ROUTE.SIGN_IN,
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default function Routes() {
  const [userInfoState, setUserInfoState] = useState()
  const storeState = useSelector((state) => state)
  const dispatch = useThunkDispatch()
  useEffect(() => {
    dispatch(getUserAction())
      .then((res) => {
        if (
          storeState.User &&
          storeState.User.user &&
          storeState.User.user.roleUser &&
          storeState.User.user.roleUser.level
        ) {
          setUserInfoState(storeState.User.user.roleUser.level)
        }
      })
      .catch((err) => {})
  }, [])

  useEffect(() => {
    if (
      storeState.User &&
      storeState.User.user &&
      storeState.User.user.roleUser &&
      storeState.User.user.roleUser.level
    ) {
      setUserInfoState(storeState.User.user.roleUser.level)
    }
  }, [storeState])

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            {userInfoState !== null && (
              <PrivateRoute path="/dashboard">
                <Dashboard />
              </PrivateRoute>
            )}
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  )
}
