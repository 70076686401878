export const LOADING_END = 'LOADING_END'
export const LOADING_START = 'LOADING_START'
export const UPSERT_USER = 'UPSERT_USER'
export const LOGOUT = 'LOGOUT'
export const CHECK_AUTHORIZATION = 'CHECK_AUTHORIZATION'
export const COLLPSE_CHANGE = 'COLLPSE_CHANGE'
export const COLLPSE_OPEN_DRAWER = 'COLLPSE_OPEN_DRAWER'
export const CHANGE_OPEN_KEYS = 'CHANGE_OPEN_KEYS'
export const TOGGLE_ALL = 'TOGGLE_ALL'
export const CHANGE_CURRENT = 'CHANGE_CURRENT'
export const CLEAR_MENU = 'CLEAR_MENU'
export const SAVE_USER = 'SAVE_USER'
