import adminActions from './actions'

const initState = {
  howToUse: '',
  responseImportCsv: undefined,
  errorResponseImportCsv: undefined,
  getShipmentsByIdBuldLoad: undefined,
  getShipment: undefined,
  getShipmentPayment: undefined,
  getPayment: undefined,
  applyDiscount: undefined,
  getShipmentList: undefined,
}

export default function bulkLoadReducer(state = initState, action) {
  switch (action.type) {
    case 'UPDATE_HOW_TO_USE':
      return {
        ...state,
        howToUse: action.payload,
      }
    case 'RESPONSE_IMPORT_CSV':
      return {
        ...state,
        responseImportCsv: action.payload,
      }
    case 'ERROR_RESPONSE_IMPORT_CSV':
      return {
        ...state,
        errorResponseImportCsv: action.error,
      }
    case 'GET_SHIPMENTS_BY_ID_BULDLOAD':
      return {
        ...state,
        getShipmentsByIdBuldLoad: action.payload,
      }
    case 'GET_SHIPMENT_BY_ID':
      return {
        ...state,
        getShipment: action.payload,
      }
    case 'GET_SHIPMENT_BY_ID_PAYMENT':
      return {
        ...state,
        getShipmentPayment: action.payload,
      }
    case 'GET_PAYMENT_BY_BULKLOADID':
      return {
        ...state,
        getPayment: action.payload,
      }
    case 'GET_APPLY_DISCOUNT':
      return {
        ...state,
        applyDiscount: action.payload,
      }
    case 'GET_SHIPMENT_LIST':
      return {
        ...state,
        getShipmentList: action.payload,
      }
    case 'RESET_RESPONSE_IMPORT_CSV':
      return {
        responseImportCsv: initState.responseImportCsv,
      }
    default:
      return state
  }
}
