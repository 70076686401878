import adminActions from './actions'

const initState = {
  shipments: [],
  origin: {},
  aditionals: {},
  amendmentCollection: [],
}

export default function adminShipReducer(state = initState, action) {
  const { amendmentCollection } = state
  switch (action.type) {
    case adminActions.GET_ORIGIN:
      return {
        ...state,
        origin: action.origin,
      }
    case adminActions.GET_SHIPMENTS:
      return {
        ...state,
        shipments: action.shipments,
      }
    case adminActions.GET_ADITIONALS:
      return {
        ...state,
        aditionals: action.aditionals,
      }
    case adminActions.CHANGE_TAGS:
      return {
        ...state,
        aditionals: { ...state.aditionals, tags: action.onTags },
      }
    case adminActions.CHANGE_ASSURENCE:
      return {
        ...state,
        aditionals: { ...state.aditionals, assurance: action.onAssurance },
      }
    case adminActions.CALCULATE_AMOUNT:
      return {
        ...state,
        aditionals: {
          ...state.aditionals,
          assurance: { ...state.aditionals.assurance, declaredAmount: action.totalAmount },
        },
      }

    case adminActions.CHANGE_ORIGIN:
      return {
        ...state,
        origin: action.newOrigin,
      }
    case adminActions.CHANGE_PACKAGE:
    case adminActions.CHANGE_DESTINY:
      return {
        ...state,
        shipments: action.onShipments,
      }

    case adminActions.COLLECT_AMENDMENT:
      return {
        ...state,
        amendmentCollection: amendmentCollection.concat(action.newAmendment),
      }
    case adminActions.CLEAR_STATE:
      return {
        ...state,
        shipments: [],
        origin: {},
        amendmentCollection: [],
      }

    default:
      return state
  }
}
