export const jwtHeader = (raw) => {
  const jwtStorage = localStorage.getItem('jwt')
  if (jwtStorage !== null) {
    if (raw) {
      return {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${jwtStorage}`,
      }
    }
    return {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${jwtStorage}`,
    }
  } else {
    return false
  }
}
