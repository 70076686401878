import AppProvider from './AppProvider'
import Boot from './redux/boot'
import GlobalStyles from '@iso/assets/styles/globalStyle'
import { Provider } from 'react-redux'
import React from 'react'
import Routes from './router'
import { store } from './redux/store'

const App = () => (
  <Provider store={store}>
    <AppProvider>
      <>
        <GlobalStyles />
        <Routes />
      </>
    </AppProvider>
  </Provider>
)
Boot()
  .then(() => App())
  .catch((error) => console.error(error))

export default App
