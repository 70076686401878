import { DeleteCell } from '@iso/components/Tables/HelperCells'
import React from 'react'

const renderDeleteCellBtn = (text, record) => (
  <span>
    <DeleteCell
      onDeleteCell={(deleteColumn) => {
        deleteColumn(record)
      }}
    />
  </span>
)

function createColumns(editColumn, deleteColumn) {
  return [
    {
      title: 'Number',
      dataIndex: 'number',
      rowKey: 'number',
    },
    {
      title: 'Full Name',
      dataIndex: 'name',
      rowKey: 'name',
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      rowKey: 'notes',
    },
    {
      title: '',
      rowKey: 'action',
      render: (deleteColumn) => renderDeleteCellBtn,
    },
  ]
}

const fakedata = [
  {
    id: 3,
    key: 1,
    number: '**** **** **** 4243',
    name: 'John Brown',
    notes: 'Nulla vitae elit libero, a pharetra augue.',
  },
  {
    id: 2,
    key: 2,
    number: '**** **** **** 4242',
    name: 'Jim Green',
    notes: 'Nullam id dolor id nibh ultricies vehicula ut id elit.',
  },
  {
    id: 1,
    key: 3,
    number: '**** **** **** 4241',
    name: 'Joe Black',
    notes: 'Nulla vitae elit libero, a pharetra augue.',
  },
]
export { createColumns, fakedata }
