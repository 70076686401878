import { postAnalyticsCampaign, putAnalytics } from '../../services/analytics.js'

export const analyticsActions = {
  RESPONSE_POST_ANALYTICS: 'RESPONSE_POST_ANALYTICS',
  RESPONSE_PUT_ANALYTICS: 'RESPONSE_PUT_ANALYTICS',
  post_analytics: (campaign, campaign_id, description) => {
    const body = {
      campaign: campaign,
      campaign_id: campaign_id,
      description: description,
    }
    return (dispatch, getState) => {
      postAnalyticsCampaign(body).then((res) => {
        dispatch({
          type: analyticsActions.RESPONSE_POST_ANALYTICS,
          payload: res,
        })
      })
    }
  },
  put_analytics: (user_id) => {
    return (dispatch, getState) => {
      putAnalytics(user_id).then((res) => {
        dispatch({
          type: analyticsActions.RESPONSE_PUT_ANALYTICS,
          payload: res,
        })
      })
    }
  },
}
