import {
  getHowToUse,
  postCSV,
  getShipmentsBulkUpload,
  getShipmentByID,
  getPaymentID,
  getApplyDiscount,
  getShipmentList,
  getShipmentByIDPayment,
} from '../../services/bulkLoadService.js'

export const bulkLoadActions = {
  UPDATE_HOW_TO_USE: 'UPDATE_HOW_TO_USE',
  RESPONSE_IMPORT_CSV: 'RESPONSE_IMPORT_CSV',
  GET_SHIPMENTS_BY_ID_BULDLOAD: 'GET_SHIPMENTS_BY_ID_BULDLOAD',
  GET_SHIPMENT_BY_ID: 'GET_SHIPMENT_BY_ID',
  GET_SHIPMENT_BY_ID_PAYMENT: 'GET_SHIPMENT_BY_ID_PAYMENT',
  GET_PAYMENT_BY_BULKLOADID: 'GET_PAYMENT_BY_BULKLOADID',
  GET_APPLY_DISCOUNT: 'GET_APPLY_DISCOUNT',
  GET_SHIPMENT_LIST: 'GET_SHIPMENT_LIST',
  RESET_RESPONSE_IMPORT_CSV: 'RESET_RESPONSE_IMPORT_CSV',
  set_how_to_use: () => {
    return (dispatch, getState) => {
      getHowToUse().then((res) => {
        dispatch({
          type: bulkLoadActions.UPDATE_HOW_TO_USE,
          payload: res.data,
        })
      })
    }
  },
  import_csv: (csv) => {
    return (dispatch, getState) => {
      postCSV(csv).then((res) => {
        dispatch({
          type: bulkLoadActions.RESPONSE_IMPORT_CSV,
          payload: res,
        })
      })
    }
  },
  get_shipment_by_buldLoad_id: (responseCsv) => {
    return (dispatch, getState) => {
      getShipmentsBulkUpload(responseCsv).then((res) => {
        dispatch({
          type: bulkLoadActions.GET_SHIPMENTS_BY_ID_BULDLOAD,
          payload: res.data,
        })
      })
    }
  },
  get_shipment: (dataShipment) => {
    return (dispatch, getState) => {
      getShipmentByID(dataShipment).then((res) => {
        dispatch({
          type: bulkLoadActions.GET_SHIPMENT_BY_ID,
          payload: res.data,
        })
      })
    }
  },
  get_shipment_payment: (dataShipment) => {
    return (dispatch, getState) => {
      getShipmentByIDPayment(dataShipment).then((res) => {
        dispatch({
          type: bulkLoadActions.GET_SHIPMENT_BY_ID_PAYMENT,
          payload: res.data,
        })
      })
    }
  },
  get_payment: (dataBulkLoad) => {
    return (dispatch, getState) => {
      getPaymentID(dataBulkLoad).then((res) => {
        dispatch({
          type: bulkLoadActions.GET_PAYMENT_BY_BULKLOADID,
          payload: res.data,
        })
      })
    }
  },
  get_apply_discount: (discount) => {
    return (dispatch, getState) => {
      getApplyDiscount(discount).then((res) => {
        dispatch({
          type: bulkLoadActions.GET_APPLY_DISCOUNT,
          payload: res.data,
        })
      })
    }
  },
  get_shipment_list: (bulkLoadId) => {
    return (dispatch, getState) => {
      getShipmentList(bulkLoadId).then((res) => {
        dispatch({
          type: bulkLoadActions.GET_SHIPMENT_LIST,
          payload: res.data,
        })
      })
    }
  },
  reset_import_csv: () => {
    return (dispatch) => {
      console.log('action')
      dispatch({
        type: bulkLoadActions.RESET_RESPONSE_IMPORT_CSV,
      })
    }
  },
}
