import {
  CHECK_AUTHORIZATION,
  LOADING_END,
  LOADING_START,
  LOGOUT,
  SAVE_USER,
  UPSERT_USER,
} from '../actions/types'
import { clearToken, getToken } from '@iso/lib/helpers/utility'

// import { stringToDates } from 'client/lib/util'
import { Redirect } from 'react-router-dom'

export const initialState = {
  jwt: localStorage.jwt || null,
  user: localStorage.user ? JSON.parse(localStorage.user) : null,
  loading: false,
  errorMessage: '',
}

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPSERT_USER:
      const { payload } = action
      const { jwt, user, rememberMe } = payload
      const userData = Object.assign(user, { rememberMe: parseInt(rememberMe) })
      localStorage.setItem('jwt', jwt)
      localStorage.setItem('user', JSON.stringify(userData))
      // const userDataObject = stringToDates(payload.data) // TODO fix this shit, use strings everywhere
      return {
        ...state,
        jwt,
        user: userData,
      }
    case SAVE_USER:
      const payloadAction = action.payload
      const userPayload = payloadAction.user
      const dataUser = Object.assign(userPayload)
      return {
        ...state,
        user: dataUser,
      }
    case LOGOUT:
      localStorage.removeItem('jwt')
      localStorage.removeItem('user')
      return { ...state, jwt: null, user: null }
    case LOADING_START:
      return { ...state, loading: true }
    case LOADING_END:
      return { ...state, loading: false }
    case CHECK_AUTHORIZATION:
      const token = getToken().get('jwt')
      if (!token) {
        localStorage.removeItem('jwt')
        return { ...state, jwt: null, user: null }
      }
      localStorage.setItem('jwt', token)
      return { ...state, loading: false }
    default:
      return state
  }
}

export default AuthReducer
