import { compose } from 'redux'

const adminActions = {
  GET_ORIGIN: 'GET_ORIGIN',
  GET_SHIPMENTS: 'GET_SHIPMENTS',
  GET_ADITIONALS: 'GET_ADITIONALS',
  CHANGE_ORIGIN: 'CHANGE_ORIGIN',
  CHANGE_DESTINY: 'CHANGE_DESTINY',
  CHANGE_PACKAGE: 'CHANGE_PACKAGE',
  CHANGE_TAGS: 'CHANGE_TAGS',
  CHANGE_ASSURENCE: 'CHANGE_ASSURENCE',
  COLLECT_AMENDMENT: 'COLLECT_AMENDMENT',
  CLEAR_STATE: 'CLEAR_STATE',
  CALCULATE_AMOUNT: 'CALCULATE_AMOUNT',

  getOrigin: (origin) => {
    return (dispatch) => {
      dispatch({
        type: adminActions.GET_ORIGIN,
        origin,
      })
    }
  },

  getOldShipments: (shipments) => {
    return (dispatch) => {
      dispatch({
        type: adminActions.GET_SHIPMENTS,
        shipments,
      })
    }
  },

  getAditionals: (aditionals) => {
    return (dispatch) => {
      dispatch({
        type: adminActions.GET_ADITIONALS,
        aditionals,
      })
    }
  },

  changeAditionals: (value, type) => {
    return (dispatch, getState) => {
      if (type && type === 'wantsTags') {
        const onTags = getState().AdminShipment.aditionals.tags
        onTags.wantsTags = value && value
        dispatch({
          type: adminActions.CHANGE_TAGS,
          onTags,
        })
      }
      if (type && type === 'wantsLoadAssurance') {
        const onAssurance = getState().AdminShipment.aditionals.assurance
        if (value) {
          onAssurance.wantsLoadAssurance = value && value
        } else {
          onAssurance.wantsLoadAssurance = value && value
          onAssurance.assuranceCost = 0
        }
        dispatch({
          type: adminActions.CHANGE_ASSURENCE,
          onAssurance,
        })
      }
      let totalAmount = 0
      const onShipments = getState().AdminShipment.shipments
      onShipments.map((a, i) => {
        const acc = a.packages.reduce((b, c) => b + Number(c.declaredAmount), 0)
        return (totalAmount = totalAmount + acc)
      })
      return
    }
  },

  calculateAmount: () => {
    return (dispatch, getState) => {
      let totalAmount = 0
      const onShipments = getState().AdminShipment.shipments
      onShipments.map((a, i) => {
        const acc = a.packages.reduce((b, c) => b + Number(c.declaredAmount), 0)
        return (totalAmount = totalAmount + acc)
      })
      dispatch({
        type: adminActions.CALCULATE_AMOUNT,
        totalAmount,
      })
    }
  },

  changeOrigin: (field, value) => {
    return (dispatch, getState) => {
      const oldOrigin = getState().AdminShipment.origin
      const newOrigin = { ...oldOrigin, [field]: value }
      dispatch({
        type: adminActions.CHANGE_ORIGIN,
        newOrigin,
      })
    }
  },

  changeDestiny: (indexShipment, field, value) => {
    return (dispatch, getState) => {
      const onShipments = getState().AdminShipment.shipments
      const oldDestiny = onShipments[indexShipment].destiny
      const newDestiny = { ...oldDestiny, [field]: value }
      onShipments[indexShipment].destiny = newDestiny
      dispatch({
        type: adminActions.CHANGE_DESTINY,
        onShipments,
      })
    }
  },
  changePackage: (s_i, p_i, field, value) => {
    return (dispatch, getState) => {
      const onShipments = getState().AdminShipment.shipments
      const oldPackage = onShipments[s_i].packages[p_i]
      const newPackage = { ...oldPackage, [field]: value }
      onShipments[s_i].packages[p_i] = newPackage
      dispatch({
        type: adminActions.CHANGE_PACKAGE,
        onShipments,
      })
    }
  },
  amendmentsCollector: (
    field,
    newValue,
    oldValue,
    orderId = null,
    shipmentId = null,
    packageId = null,
    old_aesa_id,
    userId,
  ) => {
    const newAmendment = {
      order_id: orderId && orderId,
      shipment_id: shipmentId && shipmentId,
      package_id: packageId && packageId,
      field: field && field,
      old_value: oldValue,
      new_value: newValue,
      old_aesa_id: old_aesa_id || '',
      user_id: userId,
    }
    return (dispatch) => {
      dispatch({
        type: adminActions.COLLECT_AMENDMENT,
        newAmendment,
      })
    }
  },
  clearState: () => {
    return (dispatch) => {
      dispatch({
        type: adminActions.CLEAR_STATE,
      })
    }
  },
}

export default adminActions
