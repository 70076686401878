import AppLocale from '@iso/config/translation'
import { ConfigProvider } from 'antd'
import { IntlProvider } from 'react-intl'
import React from 'react'
import { ThemeProvider } from 'styled-components'
import defaultTheme from '@iso/config/theme/default'
import language from '@iso/config/language.config'

export default function AppProvider({ children }) {
  const { locale } = language
  const currentAppLocale = AppLocale[locale]
  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  )
}
