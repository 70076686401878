import antdES from 'antd/lib/locale-provider/es_ES'
import esMessages from '../locales/es_ES.json'

const esLang = {
  messages: {
    ...esMessages,
  },
  antd: antdES,
  locale: 'es',
}
export default esLang
